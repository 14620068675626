import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

export const ADD_UAT_TO_STAFF = 'addUatToStaff';
export const DELETE_UAT_FROM_STAFF = 'removeUatToStaff';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    [ADD_UAT_TO_STAFF](context, payload) {
        context.commit('shared/activateLoading', 'admin/addUatToStaff', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post(`/territorial-unit-allocations`, payload)
            .then(() => {
                Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                resolve();
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addUatToStaff', { root: true });
            })
        });
    },
    [DELETE_UAT_FROM_STAFF](context, id) {
        context.commit('shared/activateLoading', 'admin/deleteUatFromStaff', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.delete(`territorial-unit-allocations/${id}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteUatFromStaff', { root: true });
            })
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
