import ApiService from '@/shared/services/api.service';

export class UrbanismRegulationsService {
    static get(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/urbanism-regulations?query=zone_id eq ${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/urbanism-regulations/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateBulk(zone_id, data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/urbanism-regulations/${zone_id}/bulk`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

}
