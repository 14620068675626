import ApiService from '@/shared/services/api.service';
import Vue from "vue";
import Errors from '@/shared/error/errors';

export const FETCH_FUNCTIONARY_ACCOUNTS = 'fetchFunctionaryAccounts';
export const DO_FETCH_FUNCTIONARY_ACCOUNTS = 'doFetchFunctionaryAccounts';
export const SELECT_FUNCTIONARY_ACCOUNT = 'selectFunctionaryAccount';
export const DO_FETCH_SYSTEM_FUNCTIONS = 'doFetchSystemFunctions';
export const DO_RESET_FILTERS = 'doResetFilters';

// mutation types
export const UPDATE_FUNCTIONARY_ACCOUNT = 'updateFunctionaryAccount';
export const ADD_FUNCTIONARY_ACCOUNT = 'addFunctionaryAccount';
export const DELETE_FUNCTIONARY_ACCOUNT = 'deleteFunctionaryAccount';

export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const RESET_FILTERS = 'resetFilters';


const state = {
    accounts: [],
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    getFunctionaryAccounts(state) {
        return state.accounts;
    },
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};

const actions = {
    [DO_FETCH_FUNCTIONARY_ACCOUNTS](context, {payload, query}) {
        context.commit('shared/activateLoading', 'admin/fetchFunctionaryAccounts', { root: true });

        if (payload?.query) {
            payload.query = `${query} and ` + payload.query;
        } else {
            payload.query = `${query}`;
        }

        const filters = { params: { ...payload, pagination: true } };

        return new Promise((resolve, reject) => {
            ApiService.query('users', filters)
                .then(({ data }) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data.pagination.total,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/fetchFunctionaryAccounts', { root: true });
                })
        });
    },
    [FETCH_FUNCTIONARY_ACCOUNTS](context, payload) {
        context.commit('shared/activateLoading', 'administrator/fetchFunctionaryAccounts', { root: true });
        return new Promise((resolve) => {
            ApiService.query('users', payload).then(({data}) => {
                context.commit(FETCH_SUCCESS, {
                    rows: data.data,
                    total: data.pagination.total,
                });
                resolve();
            }).catch(( error ) => {
                Errors.handle(error);
            }).finally(() => {
                context.commit('shared/deactivateLoading', 'administrator/fetchFunctionaryAccounts', { root: true });
            })
        });
    },
    [DO_FETCH_SYSTEM_FUNCTIONS](context, id) {
        context.commit('shared/activateLoading', 'administrator/doFetchSystemFunctions', { root: true });
        return new Promise((resolve) => {
            ApiService.query('functions', { params: {
                    query: `organisation_id eq ${id} and system_default eq true`,
                },
            }).then(({data}) => {
                resolve(data.data);
            }).catch(( error ) => {
                Errors.handle(error);
            }).finally(() => {
                context.commit('shared/deactivateLoading', 'administrator/doFetchSystemFunctions', { root: true });
            })
        });
    },
    [SELECT_FUNCTIONARY_ACCOUNT](context, payload) {
        context.commit('administrator/form/setSelectedFunctionaryAccount', payload, { root: true });
    },
    [DO_RESET_FILTERS](context) {
        context.commit(RESET_FILTERS);
    }
};

const mutations = {
    [ADD_FUNCTIONARY_ACCOUNT](state, account) {
        state.accounts.push(account);
    },
    [UPDATE_FUNCTIONARY_ACCOUNT](state, account) {
        const index = state.accounts.findIndex((item) => item.id === account.id);
        Vue.set(state.accounts, index, account);
    },
    [DELETE_FUNCTIONARY_ACCOUNT](state, account) {
        const index = state.accounts.findIndex((item) => item.id === account.id);
        state.accounts.splice(index, 1);
    },
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters = {
            ...state.storeFilters,
            [key]: value,
        }
    },
    [RESET_FILTERS](state) {
        state.storeFilters = {};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
