import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';

export const ADD_FUNCTIONARY_ACCOUNT = 'addFunctionaryAccount';
export const GET_FUNCTION_RESPONSIBILITIES = 'getFunctionResponsibilities';
export const GET_FUNCTIONARY_ACCOUNT = 'getFunctionaryAccount';
export const PATCH_FUNCTIONARY_ACCOUNT = 'patchFunctionaryAccount';
export const DELETE_FUNCTIONARY_ACCOUNT = 'deleteFunctionaryAccount';
export const ADD_FUNCTIONARY_ASSOCIATIONS = 'addFunctionaryAssociations';
export const GET_FUNCTIONARY_PROFILE = 'getFunctionaryProfile';
export const GET_FUNCTIONARY_ACCOUNT_PERMISSIONS = 'getFunctionaryAccountPermissions';
export const RESET_FUNCTIONARY_ACCOUNT_PERMISSIONS = 'resetFunctionaryAccountPermissions';
export const CHANGE_ACCOUNT_STATUS = 'changeAccountStatus';

// mutation types
export const SET_FUNCTIONARY_ACCOUNTS = 'setFunctionaryAccounts';
export const SET_SELECTED_FUNCTIONARY_ACCOUNT = 'setSelectedFunctionaryAccount';
export const SET_SELECTED_FUNCTIONARY_PROFILE = 'setSelectedFunctionaryProfile';
export const SET_SELECTED_FUNCTIONARY_ACCOUNT_PERMISSIONS = 'setSelectedFunctionaryAccountPermissions';
export const RESET_SELECTED_FUNCTIONARY_ACCOUNT_PERMISSIONS = 'resetSelectedFunctionaryAccountPermissions';


const state = {
    selectedAccount : null,
    selectedAccountProfile: null,
    selectedAccountPermissions: [],
};

const getters = {
    getSelectedFunctionaryAccount: (state) => state.selectedAccount,
    getSelectedFunctionaryProfile: (state) => state.selectedAccountProfile,
    getSelectedFunctionaryAccountPermissions: (state) => state.selectedAccountPermissions,
};

const actions = {
    [ADD_FUNCTIONARY_ACCOUNT](context, payload) {
        context.commit('shared/activateLoading', 'administrator/addFunctionaryAccount', { root: true });
        return new Promise((resolve) => {
            ApiService.post('users/staff', payload)
                .then(({ data }) => {
                    context.commit('administrator/list/addFunctionaryAccount', payload, { root: true });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/addFunctionaryAccount', { root: true });
                });
        });
    },
    [PATCH_FUNCTIONARY_ACCOUNT](context, {id, payload}) {
        context.commit('shared/activateLoading', 'administrator/patchFunctionaryAccount', { root: true });
        return new Promise((resolve) => {
            ApiService.patch(`users/staff/${id}`, payload)
                .then(({ data }) => {
                    context.commit('administrator/list/updateFunctionaryAccount', payload, { root: true});
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/patchFunctionaryAccount', { root: true });
                });
        });
    },
    [CHANGE_ACCOUNT_STATUS](context, {id, payload}) {
        context.commit('shared/activateLoading', 'administrator/changeAccountStatus', { root: true });
        return new Promise((resolve) => {
            ApiService.patch(`users/status/${id}`, payload)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/changeAccountStatus', { root: true });
                });
        });
    },
    [DELETE_FUNCTIONARY_ACCOUNT](context, id) {
        context.commit('shared/activateLoading', 'administrator/deleteFunctionaryAccount', { root: true });
        return new Promise((resolve) => {
            ApiService.delete(`users/${id}`)
                .then(({ data }) => {
                    context.commit('administrator/list/deleteFunctionaryAccount', data, { root: true });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/deleteFunctionaryAccount', { root: true });
                });
        });
    },
    [ADD_FUNCTIONARY_ASSOCIATIONS](context, payload) {
        context.commit('shared/activateLoading', 'admin/addFunctionaryAssociations', { root: true });
        return new Promise((resolve) => {
            ApiService.post('association-requests/multiple', payload)
            .then(() => {
                resolve();
            })
            .catch(({ error }) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addFunctionaryAssociations', { root: true });
            })
        });
    },
    [GET_FUNCTION_RESPONSIBILITIES](context, id) {
        context.commit('shared/activateLoading', 'administrator/getFunctionResponsibilities', { root: true });
        return new Promise(resolve => {
            ApiService.get(`functions/${id}`)
                .then(({data}) => {
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/getFunctionResponsibilities', { root: true });
                });
        });
    },
    [GET_FUNCTIONARY_ACCOUNT](context, id) {
        context.commit('shared/activateLoading', 'administrator/getFunctionaryAccount', { root: true });
        return new Promise((resolve) => {
            ApiService.get(`users/${id}`)
                .then(({data}) => {
                    context.commit(SET_SELECTED_FUNCTIONARY_ACCOUNT, data);
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/getFunctionaryAccount', { root: true });
                });
        });
    },
    [GET_FUNCTIONARY_PROFILE](context, id) {
        context.commit('shared/activateLoading', 'administrator/getFunctionaryProfile', { root: true });
        return new Promise((resolve) => {
            ApiService.query(`users/staff/${id}`)
                .then(({data}) => {
                    context.commit(SET_SELECTED_FUNCTIONARY_PROFILE, data);
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/getFunctionaryProfile', { root: true });
                });
        });
    },
    [GET_FUNCTIONARY_ACCOUNT_PERMISSIONS](context, id) {
        context.commit('shared/activateLoading', 'administrator/getFunctionaryAccountPermissions', { root: true });
        return new Promise((resolve) => {
            ApiService.get(`users/${id}/responsibilities-permissions`)
                .then(({data}) => {
                    context.commit(SET_SELECTED_FUNCTIONARY_ACCOUNT_PERMISSIONS, data);
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/getFunctionaryAccountPermissions', { root: true });
                });
        });
    },
    [RESET_FUNCTIONARY_ACCOUNT_PERMISSIONS](context) {
        context.commit(RESET_SELECTED_FUNCTIONARY_ACCOUNT_PERMISSIONS);
    }
};

const mutations = {
    [SET_SELECTED_FUNCTIONARY_ACCOUNT](state, account) {
        state.selectedAccount = account;
    },
    [SET_SELECTED_FUNCTIONARY_PROFILE](state, profile) {
        state.selectedAccountProfile = profile;
    },
    [SET_SELECTED_FUNCTIONARY_ACCOUNT_PERMISSIONS](state, permissions) {
        state.selectedAccountPermissions = permissions;
    },
    [RESET_SELECTED_FUNCTIONARY_ACCOUNT_PERMISSIONS](state) {
        state.selectedAccountPermissions = [];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
