import Errors from '@/shared/error/errors';
import ApiService from '@/shared/services/api.service';
import { InstitutionsPermissions } from '@/modules/administrator/institutions-permissions';
// action types
export const DO_FETCH = 'doFetch';
export const GET_ORGANISATIONS = 'getOrganisations';
export const GET_ORGANISATION = 'getOrganisation';
export const DO_SELECT_ORGANISATION = 'selectOrganisation';
export const DO_UNSELECT_ORGANISATION = 'unselectOrganisation';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_SELECTED_ORG = 'setSelectedOrg';
export const REMOVE_SELECTED_ORG = 'removeSelectedOrg';
export const REMOVE_RECORD = 'removeOrganisation';
export const SET_USER_PERMISSIONS = 'setUserPermissions';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    selectedOrganisation: '',
    user: {},
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    selectedOrganisationId: (state) => state.selectedOrganisation,
    orgPermissions : (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToList: new InstitutionsPermissions(currentUser).read,
            hasPermissionToEdit: new InstitutionsPermissions(currentUser).edit,
            hasPermissionToDestroy: new InstitutionsPermissions(currentUser).destroy,
            hasPermissionToViewFunctions: new InstitutionsPermissions(currentUser).viewFunctions,
            hasPermissionToViewFunctionsUsers: new InstitutionsPermissions(currentUser).viewFunctionsUsers,
            hasPermissionToEditFunctions: new InstitutionsPermissions(currentUser).editFunctions,
            hasPermissionToDestroyFunctions: new InstitutionsPermissions(currentUser).destroyFunctions,
            hasPermissionToViewDepartments: new InstitutionsPermissions(currentUser).viewDepartments,
            hasPermissionToEditDepartments: new InstitutionsPermissions(currentUser).editDepartments,
            hasPermissionToViewDepartmentsFunc: new InstitutionsPermissions(currentUser).viewDepartmentsFunctions,
            hasPermissionToDestroyDepartments: new InstitutionsPermissions(currentUser).destroyDepartments,
            hasPermissionToViewUsers: new InstitutionsPermissions(currentUser).viewUsers,
            hasPermissionToEditUsers: new InstitutionsPermissions(currentUser).editStaffUsers,
            hasPermissionToResetPasswordUsers: new InstitutionsPermissions(currentUser).resetPasswordStaffUsers,
            hasPermissionToDestroyUsers: new InstitutionsPermissions(currentUser).destroyStaffUsers,
            hasPermissionToViewTaxes: new InstitutionsPermissions(currentUser).viewTaxes,
            hasPermissionToViewBankAccounts: new InstitutionsPermissions(currentUser).viewBankAccounts,
            hasPermissionToEditBankAccounts: new InstitutionsPermissions(currentUser).editBankAccounts,
            hasPermissionToDestroyBankAccounts: new InstitutionsPermissions(currentUser).destroyBankAccounts,
            hasPermissionToViewDocumentSeries: new InstitutionsPermissions(currentUser).viewDocumentSeries,
            hasPermissionToEditDocumentSeries: new InstitutionsPermissions(currentUser).editDocumentSeries,
            hasPermissionToViewOrgResponsibilities: new InstitutionsPermissions(currentUser).viewOrgResponsibilities,
            hasPermissionToEditResponsibilities: new InstitutionsPermissions(currentUser).editOrgResponsibilities,
            hasPermissionToDestroyResponsibilities: new InstitutionsPermissions(currentUser).destroyOrgResponsibilities,
            hasPermissionToViewResponsibilities: new InstitutionsPermissions(currentUser).viewOrgResponsibilities,
            hasPermissionToViewDecisions: new InstitutionsPermissions(currentUser).viewOrgDecisions,
            hasPermissionToEditDecision: new InstitutionsPermissions(currentUser).editOrgDecisions,
            hasPermissionToDestroyDecision: new InstitutionsPermissions(currentUser).destroyOrgDecisions,
            hasPermissionToViewTerritorialUnitAllocation: new InstitutionsPermissions(currentUser).viewOrgTerritorialUnitAllocation,
            hasPermissionToDestroyTerritorialUnitAllocation: new InstitutionsPermissions(currentUser).destroyOrgTerritorialUnitAllocation,
            hasPermissionToEditTax: new InstitutionsPermissions(currentUser).editTaxes,
        }
    },
};

const actions = {
    [GET_ORGANISATIONS](context, {payload, orgId}) {
        context.commit('shared/activateLoading', 'admin/getOrganisations', { root: true });
        if (orgId) {
            return context.dispatch('getOrganisation', orgId);
        }
        const filters = { params: { ...payload, pagination: true } };
        return new Promise((resolve, reject) => {
            ApiService.query('/organisations', filters)
                .then(({ data }) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data.pagination.total,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getOrganisations', { root: true });
                })
        });
    },
    [GET_ORGANISATION](context, id) {
        context.commit('shared/activateLoading', 'admin/getOrganisations', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`/organisations/${id}`)
                .then(({ data }) => {

                    context.commit(FETCH_SUCCESS, {
                        rows: [data],
                        total: 1,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getOrganisations', { root: true });
                })
        });
    },
    [DO_SELECT_ORGANISATION](context, organisation) {
        context.commit(SET_SELECTED_ORG, organisation);
    },
    [DO_UNSELECT_ORGANISATION](context) {
        context.commit(REMOVE_SELECTED_ORG);
    },
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_SELECTED_ORG](state, org) {
        state.selectedOrganisation = org;
    },
    [REMOVE_SELECTED_ORG](state) {
        state.selectedOrganisation = '';
    },
    [REMOVE_RECORD](state, id) {
        state.rows = state.rows.filter((el) => el.id !== id);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
