
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';
import ApiService from '@/shared/services/api.service';

// action types
export const ADD_PERMISSIONS_TO_RESPONSIBILITY = 'addPermissionsToResponsibility';
export const FETCH_PERMISSIONS_BY_ORG_SCOPE = 'fetchPermissionsByOrgScope';
export const FETCH_PERMISSIONS_BY_RESPONSIBILITY = 'fetchPermissionsByResponsibility';
export const UPDATE_PERMISSIONS_TO_RESPONSIBILITY = 'updatePermissionsToResponsibility';
export const FETCH_RESPONSIBILITIES = 'fetchResponsibilities';

// mutation types
export const SET_PERMISSIONS = 'setPermissions';
export const SET_RESPONSIBILITIES = 'setPermissions';
export const SET_PERMISSIONS_BY_RESPONSIBILITY = 'setPermissionsByResponsibility';

const state = {
    permissionsByOrgScope: [],
    permissionsByResponsibility: [],
    responsibilities: [],
};

const getters = {
    permissionsByOrgScope: (state) => state.permissionsByOrgScope,
    permissionsByResponsibility: (state) => state.permissionsByResponsibility,
    responsibilities: (state) => state.responsibilities,
};

const mutations = {
    [SET_PERMISSIONS](state, permissions) {
        state.permissionsByOrgScope = permissions;
    },
    [SET_PERMISSIONS_BY_RESPONSIBILITY](state, permissions) {
        state.permissionsByResponsibility = permissions;
    },
};

const actions = {
    [ADD_PERMISSIONS_TO_RESPONSIBILITY](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'admin/resp/addPermissionsToResponsibility', { root: true });
            ApiService.post(`organisation-responsibilities`, payload)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/resp/addPermissionsToResponsibility', { root: true });
                });
        });
    },
    [UPDATE_PERMISSIONS_TO_RESPONSIBILITY](context, {id, permissions}) {
        context.commit('shared/activateLoading', 'admin/updatePermissionsToResponsibility', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`organisation-responsibilities/${id}`, {permissions})
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updatePermissionsToResponsibility', { root: true });
            })
        });
    },
    [FETCH_PERMISSIONS_BY_ORG_SCOPE](context) {
        context.commit('shared/activateLoading', 'admin/resp/getPermissionsByOrgScope', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('/permissions/current/organisation', { params: { limit: 100 } })
            .then(({ data }) => {
                context.commit(SET_PERMISSIONS, data.data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/resp/getPermissionsByOrgScope', { root: true });
            })
        });
    },
    [FETCH_PERMISSIONS_BY_RESPONSIBILITY](context, id) {
        context.commit('shared/activateLoading', 'admin/resp/getPermissionsByResponsibilities', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`organisation-responsibilities/${id}`)
            .then(({ data }) => {
                context.commit(SET_PERMISSIONS_BY_RESPONSIBILITY, data.data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/resp/getPermissionsByResponsibilities', { root: true });
            })
        });
    },
    [FETCH_RESPONSIBILITIES](context) {
        context.commit('shared/activateLoading', 'admin/resp/getResponsibilities', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('/responsibilities')
            .then(({ data }) => {
                context.commit(SET_RESPONSIBILITIES, data.data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/resp/getResponsibilities', { root: true });
            })
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
