import Errors from '@/shared/error/errors';
import ApiService from '@/shared/services/api.service';

export const FETCH_DEPARTMENT_USERS = 'fetchDepartmentUsers';
export const FETCH_SUCCEEDED = 'fetchSucceeded';

const state = {
    rows: [],
    total: 0,
};

const getters = {
    total: state => state.total,
    rows: state => state.rows,
};

const actions = {
    [FETCH_DEPARTMENT_USERS](context, params) {
        context.commit('shared/activateLoading', 'admin/functionUsers/fetchDepartmentUsers', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('users', { params })
                .then(({ data }) => {
                    context.commit(FETCH_SUCCEEDED, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/functionUsers/fetchDepartmentUsers', { root: true });
                })
        });
    }
};

const mutations = {
    [FETCH_SUCCEEDED](state, data) {
        state.rows = data.data;
        state.total = data.pagination.total;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
