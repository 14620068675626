import ApiService from '@/shared/services/api.service';

export class AdministratorService {

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post('/organisations?echo=true', data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/organisations/${id}`, data)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

}