import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';
import { AdministratorService } from '@/modules/administrator/administrator-service';

export const ADD_INSTITUTION = 'addInstitution';
export const UPDATE_INSTITUTION = 'updateInstitution';
export const DELETE_INSTITUTION = 'deleteInstitution';
export const DO_UPLOAD = 'upload';

const state = {};
const getters = {};

const actions = {
    [ADD_INSTITUTION](context, institution) {
        context.commit('shared/activateLoading', 'admin/addInstitution', { root: true });
        return new Promise((resolve, reject) => {
            AdministratorService.create(institution).then(({ data, headers }) => {
                if (headers["x-file-upload-access-token"]) {
                    context.commit('administrator/setUploadToken', headers["x-file-upload-access-token"], { root: true });
                }

                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addInstitution', { root: true });
            })
        });
    },
    [UPDATE_INSTITUTION](context, {id, payload}) {
        context.commit('shared/activateLoading', 'admin/updateInstitution', { root: true });
        return new Promise((resolve, reject) => {
            AdministratorService.update(id, payload).then(({ data, headers }) => {
                if (headers["x-file-upload-access-token"]) {
                    context.commit('administrator/setUploadToken', headers["x-file-upload-access-token"], { root: true });
                }

                if (headers["x-file-download-access-token"]) {
                    context.commit('administrator/setFileToken', headers["x-file-download-access-token"], { root: true });
                }

                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateInstitution', { root: true });
            })
        });
    },
    [DELETE_INSTITUTION](context, id) {
        context.commit('shared/activateLoading', 'admin/deleteInstitution', { root: true });
        return new Promise((resolve) => {
            ApiService.delete(`organisations/${id}`)
            .then(() => {
                context.commit('administrator/orgList/removeOrganisation', id, { root: true });
                resolve();
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteInstitution', { root: true });
            })
        });
    },
    [DO_UPLOAD](context, payload) {
        context.commit("shared/activateLoading", "organisations/upload", { root: true });
        return new Promise((resolve, reject) => {
            const { uploadAccessToken, files } = payload;
            
            AdministratorService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "organisations/upload", { root: true });
                });
        });
    },
}

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
