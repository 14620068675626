import Errors from '@/shared/error/errors';
import ApiService from '@/shared/services/api.service';

export const FETCH_DOCUMENT_SERIES = 'fetchDocumentSeries';
export const ADD_DOCUMENT_SERIES = 'addDocumentSeries';
export const EDIT_DOCUMENT_SERIES = 'editDocumentSeries';

export const FETCH_SUCCEEDED = 'fetchSucceeded';

const state = {
    documentSeries: [],
    total: 0,
};

const getters = {
    total: state => state.total,
    documentSeries: state => state.documentSeries,
};

const actions = {
    [FETCH_DOCUMENT_SERIES](context, params) {
        context.commit('shared/activateLoading', 'administrator/documentSeries/fetchDocumentSeries', { root: true });

        return new Promise((resolve) => {
            ApiService.query('document-series', { params })
                .then(({ data }) => {
                    context.commit(FETCH_SUCCEEDED, data);
                    resolve(data);
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/documentSeries/fetchDocumentSeries', { root: true });
                });
        });
    },
    [ADD_DOCUMENT_SERIES](context, payload) {
        context.commit('shared/activateLoading', 'administrator/documentSeries/addDocumentSeries', { root: true });

        return new Promise((resolve) => {
            ApiService.post('document-series', payload)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/documentSeries/addDocumentSeries', { root: true });
                });
        });
    },
    [EDIT_DOCUMENT_SERIES](context, { id, payload }) {
        context.commit('shared/activateLoading', 'administrator/documentSeries/editDocumentSeries', { root: true });
        
        return new Promise((resolve) => {
            ApiService.patch(`document-series/${id}`, payload)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/documentSeries/editDocumentSeries', { root: true });
                });
        });
    },
};

const mutations = {
    [FETCH_SUCCEEDED](state, data) {
        state.documentSeries = data.data;
        state.total = data.pagination.total;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
