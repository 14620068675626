import ApiService from '@/shared/services/api.service';

export class UrbanismRegulationZonesService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/urbanism-regulation-zones', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static find(key) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/urbanism-regulation-zones?query=key like '${key}'`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/urbanism-regulation-zones', { ...data } )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/urbanism-regulation-zones/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/urbanism-regulation-zones/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

}
