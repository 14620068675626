import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';

export const ADD_DEPARTMENT = 'addDepartment';
export const UPDATE_DEPARTMENT = 'updateDepartment';

const state = {};
const getters = {};
const actions = {
    [ADD_DEPARTMENT](context, payload) {
        context.commit('shared/activateLoading', 'admin/addDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post(`/departments`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addDepartment', { root: true });
            })
        });
    },
    [UPDATE_DEPARTMENT](context, {id, payload}) {
        context.commit('shared/activateLoading', 'admin/updateDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`departments/${id}`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateDepartment', { root: true });
            })
        });
    },
}
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
