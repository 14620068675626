import ApiService from '@/shared/services/api.service';

import Errors from '@/shared/error/errors';
import organisationListStore from '@/modules/administrator/store/organisation-list';
import organisationFormStore from '@/modules/administrator/store/organisation-form';
import departmentListStore from '@/modules/administrator/store/department-list';
import departmentFormStore from '@/modules/administrator/store/department-form';
import staffAccountStore from '@/modules/administrator/store/staff-account-store';
import staffListStore from '@/modules/administrator/store/staff-list-store';
import functionListStore from '@/modules/administrator/store/function-list';
import collectorAccountListStore from '@/modules/administrator/store/collector-account-list';
import documentSeriesStore from '@/modules/administrator/store/document-series-store';
import responsibilityListStore from '@/modules/administrator/store/responsibility-list';
import responsibilityFormStore from '@/modules/administrator/store/responsibility-form';
import functionUsersStore from '@/modules/administrator/store/function-users-store';
import territorialUnitAllocationListStore from '@/modules/administrator/store/territorial-unit-allocation-list';
import territorialUnitAllocationFormStore from '@/modules/administrator/store/territorial-unit-allocation-form';
import departmentUsersStore, { FETCH_DEPARTMENT_USERS } from '@/modules/administrator/store/department-users-store';
import decisionListStore from '@/modules/administrator/store/decision-list';
import decisionFormStore from '@/modules/administrator/store/decision-form';
import urbanismRegulationZonesListStore from '@/modules/administrator/store/urbanism-regulation-zones-list-store';
import urbanismRegulationZonesFormStore from '@/modules/administrator/store/urbanism-regulation-zones-form-store';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// Actions
export const GET_INSTITUTIONS = 'getInstitutions';
export const GET_INSTITUTION = 'getInstitution';
export const GET_ORG_DEPARTMENTS = 'getOrgDepartments';

export const GET_DEPARTMENT = 'getDepartment';
export const GET_DEPARTMENT_USERS = 'getDepartmentUsers';
export const ADD_DEPARTMENT = 'addDepartment';
export const UPDATE_DEPARTMENT = 'updateDepartment';

export const GET_FUNCTIONS = 'getFunctions';
export const GET_DEPARTMENT_FUNCTIONS = 'getDepartmentFunctions';
export const ADD_FUNCTION = 'addFunction';
export const UPDATE_FUNCTION = 'updateFunction';
export const GET_FUNCTION_USERS = 'getFunctionUsers';
export const ADD_FUNCTION_TO_DEPARTMENT = 'addFunctionToDepartment';

export const ADD_FUNCTIONS_TO_DEPARTMENT = 'addFunctionsToDepartment';
export const DELETE_FUNCTION_FROM_DEPARTMENT = 'deleteFunctionFromDepartment';
export const GET_COLLECTOR_ACCOUNTS = 'getCollectorAccounts';
export const ADD_ACCOUNT = 'addAccount';
export const UPDATE_ACCOUNT = 'updateAccount';

export const GET_ORGANISATIONS_DEPARTMENTS = 'getOrganisationDepartments';
export const GET_ORGANISATION_ROLES = 'getOrganisationRoles';

export const GET_FUNCTION_RESPONSIBILITIES = 'getFunctionResponsibilities';
export const GET_FUNCTIONARY_PROFILE = 'getFunctionaryProfile';
export const GET_RESPONSIBILITIES = 'getResponsibilities';
export const ADD_RESPONSIBILITIES_TO_FUNCTION = 'addResponsibilitiesToFunction';
export const FETCH_TAXES = 'fetchTaxes';
export const ADD_TAX = 'addTax';
export const UPDATE_TAX = 'updateTax';

// mutation types
export const SET_INSTITUTIONS = 'setInstitutions';
export const SET_INSTITUTION = 'setInstitution';
export const CLEAR_INSTITUTION = 'clearInstitution';
export const SET_DEPARTMENTS = 'setDepartments';
export const SET_DEPARTMENT = 'setDepartment';
export const SET_DEPARTMENT_USERS = 'setDepartmentUsers';
export const SET_FUNCTION_USERS = 'setFunctionUsers';
export const SET_USERS = 'setUsers';
export const SET_FUNCTIONS = 'setFunctions';

export const SET_COLLECTOR_ACCOUNTS = 'setCollectorAccounts';
export const SET_DEP_FUNCTIONS = 'setDepFunctions';

export const SET_RESPONSIBILITIES = 'setResponsibilities';
export const SET_TAXES = 'setTaxes';
export const SET_TOTAL_TAXES = 'setTotalTaxes';
export const SET_UPLOAD_TOKEN = "setUploadToken";
export const SET_FILE_TOKEN = "setFileToken";

const state = {
    institutions: [],
    institution: {},
    departments: [],
    department: {},
    departmentUsers: [],
    users: [],
    functions: [],
    functionUsers: {
        currentPage: 1,
        rows: [],
        total: 0
    },
    collectorAccounts: [],
    depFunctions: [],
    // taxes
    taxes: [],
    totalTaxes: 0,
    responsibilities: [],
    uploadToken: null,
    fileToken: null,
};

const getters = {
    institutions: state => state.institutions,
    institution: state => state.institution,
    departments: state => state.departments,
    department: state => state.department,
    departmentUsers: state =>  state.departmentUsers,
    users: state =>state.users,
    functions: state => state.functions,
    functionUsers: state=> state.functionUsers,
    collectorAccounts: state => state.collectorAccounts,
    depFunctions: state => state.depFunctions,
    responsibilities: state => state.responsibilities,
    taxes: state => state.taxes,
    totalTaxes: state => state.totalTaxes,
    uploadAccessToken: state => state.uploadToken,
    fileToken: (state) => state.fileToken,
};

const actions = {
    // Institutions
    [GET_INSTITUTIONS](context, params) {
        context.commit('shared/activateLoading', 'admin/getInstitutions', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('organisations', params)
                .then(({ data }) => {
                    context.commit(SET_INSTITUTIONS, data.data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getInstitutions', { root: true });
                })
        });
    },
    [GET_INSTITUTION](context, id) {
        context.commit('shared/activateLoading', 'admin/getInstitution', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`organisations/${id}`)
            .then(({ data, headers }) => {
                if (headers["x-file-upload-access-token"]) {
                    context.commit(SET_UPLOAD_TOKEN, headers["x-file-upload-access-token"]);
                }

                if (headers["x-file-download-access-token"]) {
                    context.commit(SET_FILE_TOKEN, headers["x-file-download-access-token"]);
                }

                context.commit(SET_INSTITUTION, data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/getInstitution', { root: true });
            })
        });
    },

    // Departments
    [GET_ORG_DEPARTMENTS](context, id) {
        context.commit('shared/activateLoading', 'admin/getDepartments', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('departments', {params: {limit: 100}})
                .then(({ data }) => {
                    context.commit(SET_DEPARTMENTS, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getDepartments', { root: true });
                })
        });
    },
    [GET_DEPARTMENT](context, department) {
        context.commit('shared/activateLoading', 'admin/getDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('department', {id: department.id})
            .then(({ data }) => {
                context.commit(SET_DEPARTMENT, data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/getDepartment', { root: true });
            })
        });
    },
    [ADD_DEPARTMENT](context, {orgId, department}) {
        context.commit('shared/activateLoading', 'admin/addDepartment', { root: true });
        return new Promise((resolve, reject) => {
            // TODO: Clarify endpoints  for adding departments with BE : organisations/${orgId}/departments?
            ApiService.post(`organisations/${orgId}/departments`, department)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addDepartment', { root: true });
            })
        });
    },
    [UPDATE_DEPARTMENT](context, {id, payload}) {
        context.commit('shared/activateLoading', 'admin/updateDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`departments/${id}`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateDepartment', { root: true });
            })
        });
    },

    // Users
    [GET_DEPARTMENT_USERS](context, {departmentName, orgId}) {
        context.commit('shared/activateLoading', 'admin/getDepartmentUsers', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('users', {params: {query: `organisation_id eq ${orgId} and departments in ['${departmentName}']`}})
            .then(({ data }) => {
                context.commit(SET_DEPARTMENT_USERS, data.data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/getDepartmentUsers', { root: true });
            })
        });
    },

    // Functions
    // Functions by Organisation
    [GET_FUNCTIONS](context, id) {
        context.commit('shared/activateLoading', 'admin/getFunctions', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('functions', {params: {query: `organisation_id eq ${id}`, limit: 100}})
                .then(({ data }) => {
                    context.commit(SET_FUNCTIONS, data.data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getFunctions', { root: true });
                })
        });
    },
    [GET_DEPARTMENT_FUNCTIONS](context, id) {
        context.commit('shared/activateLoading', 'admin/getDepFunctions', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('department-functions', {params: {query: `department_id eq ${id}`, limit: 100}})
                .then(({ data }) => {
                    context.commit(SET_DEP_FUNCTIONS, data.data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getDepFunctions', { root: true });
                })
        });
    },
    [ADD_FUNCTION](context, functionObj) {
        context.commit('shared/activateLoading', 'admin/addDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post(`functions`, functionObj)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addDepartment', { root: true });
            })
        });
    },
    [ADD_FUNCTION_TO_DEPARTMENT](context, payload) {
        context.commit('shared/activateLoading', 'admin/addFunctionToDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('association-requests', payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addFunctionToDepartment', { root: true });
            })
        });
    },
    [ADD_FUNCTIONS_TO_DEPARTMENT](context, payload) {
        context.commit('shared/activateLoading', 'admin/addFunctionSToDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('association-requests/multiple', payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addFunctionSToDepartment', { root: true });
            })
        });
    },
    [DELETE_FUNCTION_FROM_DEPARTMENT]( context, payload) {
        context.commit('shared/activateLoading', 'admin/deleteFunctionFromDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('association-requests', payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteFunctionFromDepartment', { root: true });
            })
        });
    },
    [UPDATE_FUNCTION](context, {id, payload}) {
        context.commit('shared/activateLoading', 'admin/updateDepartment', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`functions/${id}`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateDepartment', { root: true });
            })
        });
    },

    // users for a specific function with paging and search by name
    [GET_FUNCTION_USERS](context, {functionName, orgId, skip, limit, currentPage, searchByNameTerm}) {
        context.commit('shared/activateLoading', 'admin/getFunctionUsers', { root: true });
        return new Promise((resolve, reject) => {

            let query = `organisation_id eq ${orgId} and functions in ['${functionName}']`;
            if (searchByNameTerm) {
                query = `organisation_id eq ${orgId} and last_name contains \'${searchByNameTerm}\' and functions in ['${functionName}']`;
            }

            ApiService.query('users', {
                params: {
                    query,
                    pagination: true,
                    skip,
                    limit
                }
            })
            .then(({ data }) => {
                context.commit(SET_FUNCTION_USERS, {
                    currentPage: currentPage ?? 1,
                    rows: data.data ?? [],
                    total: data?.pagination?.total ?? 1,
                });

                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/getFunctionUsers', { root: true });
            })
        });
    },

    // users for a specific department with paging and search by name
    [FETCH_DEPARTMENT_USERS](context, {departmentName, orgId, skip, limit, currentPage, searchByNameTerm}) {
        const action = `admin/${FETCH_DEPARTMENT_USERS}`;

        context.commit('shared/activateLoading', action, { root: true });
        return new Promise((resolve, reject) => {

            let query = `organisation_id eq ${orgId} and departments in ['${departmentName}']`;
            if (searchByNameTerm) {
                query = `organisation_id eq ${orgId} and last_name contains \'${searchByNameTerm}\' and departments in ['${departmentName}']`;
            }

            ApiService.query('users', {
                params: {
                    query,
                    pagination: true,
                    skip,
                    limit
                }
            })
                .then(({ data }) => {
                    context.commit(SET_DEPARTMENT_USERS, {
                        currentPage: currentPage ?? 1,
                        rows: data.data ?? [],
                        total: data?.pagination?.total ?? 1,
                    });

                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', action, { root: true });
                })
        });
    },

    // Accounts
    [GET_COLLECTOR_ACCOUNTS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get('bank-accounts')
                .then(({ data }) => {
                    context.commit(SET_COLLECTOR_ACCOUNTS, data.data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
        });
    },

    //manage functionary accounts
    [GET_ORGANISATION_ROLES](context, payload) {
        context.commit('shared/activateLoading', 'administrator/getOrganisationRoles', { root: true });
        return new Promise(resolve => {
            ApiService.query('user-roles', payload)
                .then(({data}) => {
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/getOrganisationRoles', { root: true });
                });
        });
    },
    [GET_FUNCTION_RESPONSIBILITIES](context, payload) {
        context.commit('shared/activateLoading', 'administrator/getFunctionResponsibilities', { root: true });
        return new Promise(resolve => {
            ApiService.query('responsibilities', payload)
                .then(({data}) => {
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/getFunctionResponsibilities', { root: true });
                });
        });
    },
    [ADD_ACCOUNT](context, {orgId, account}) {
        context.commit('shared/activateLoading', 'admin/addAccount', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post(`organisations/${orgId}/bank-accounts`, account)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addAccount', { root: true });
            })
        });
    },
    [UPDATE_ACCOUNT](context, {accountId, account}) {
        context.commit('shared/activateLoading', 'admin/updateAccount', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`bank-accounts/${accountId}`, account)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateAccount', { root: true });
            })
        });
    },

    //Responsibilities
    [GET_RESPONSIBILITIES](context, org) {
        context.commit('shared/activateLoading', 'admin/getResponsibilities', { root: true });
        return new Promise(resolve => {
            ApiService.query('organisation-responsibilities', {params:{query: `organisation_id eq ${org.id}`, limit: 100}})
                .then(({data}) => {
                    context.commit(SET_RESPONSIBILITIES, data.data);
                    resolve(data);
                }).catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getResponsibilities', { root: true });
                });
        });
    },
    [ADD_RESPONSIBILITIES_TO_FUNCTION](context, {functionId, payload}) {
        context.commit('shared/activateLoading', 'admin/addResponsibilities', { root: true });
        return new Promise(resolve => {
            ApiService.post(`functions/${functionId}/responsibilities`, payload)
            .then(() => {
                Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                resolve();
            })
            .catch((error) => {
                Errors.handle(error);

            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addResponsibilities', { root: true });
            })
        });
    },

    // Taxes
    [FETCH_TAXES](context, params) {
        context.commit('shared/activateLoading', 'administrator/fetchTaxes', { root: true });

        return new Promise((resolve) => {
            ApiService.query('taxes', { params })
                .then(({ data }) => {
                    context.commit(SET_TAXES, data.data);
                    context.commit(SET_TOTAL_TAXES, data.pagination.total);
                    resolve(data);
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/fetchTaxes', { root: true });
                });
        });
    },
    [ADD_TAX](context, payload) {
        context.commit('shared/activateLoading', 'administrator/addTax', { root: true });

        return new Promise((resolve) => {
            ApiService.post('taxes', payload)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/addTax', { root: true });
                });
        });
    },
    [UPDATE_TAX](context, { id, payload }) {
        context.commit('shared/activateLoading', 'administrator/updateTax', { root: true });

        return new Promise((resolve) => {
            ApiService.patch(`taxes/${id}`, payload)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'administrator/updateTax', { root: true });
                });
        });
    },
};

const mutations = {
    [SET_INSTITUTIONS](state, institutions) {
        state.institutions = institutions;
    },
    [SET_INSTITUTION](state, institution) {
        state.institution = institution;
    },
    [CLEAR_INSTITUTION](state) {
        state.institution = {};
    },
    [SET_DEPARTMENTS](state, departments) {
        state.departments = departments;
    },
    [SET_DEPARTMENT](state, department) {
        state.department = department;
    },
    [SET_USERS](state, users) {
        state.users = users;
    },
    [SET_FUNCTIONS](state, functions) {
        state.functions = functions;
    },

    [SET_FUNCTION_USERS](state, payload) {
        state.functionUsers.currentPage = payload.currentPage ?? 1;
        state.functionUsers.rows = payload.rows ?? [];
        state.functionUsers.total = payload.total ?? 1;
    },

    [SET_DEPARTMENT_USERS](state, payload) {
        state.departmentUsers.currentPage = payload.currentPage ?? 1;
        state.departmentUsers.rows = payload.rows ?? [];
        state.departmentUsers.total = payload.total ?? 1;
    },

    [SET_COLLECTOR_ACCOUNTS](state, collectorAccounts) {
        state.collectorAccounts = collectorAccounts;
    },
    [SET_DEP_FUNCTIONS](state, depFunctions) {
        state.depFunctions = depFunctions;
    },
    [SET_RESPONSIBILITIES](state, responsibilities) {
        state.responsibilities = responsibilities;
    },

    //taxes
    [SET_TAXES](state, taxes) {
        state.taxes = taxes;
    },
    [SET_TOTAL_TAXES](state, total) {
        state.totalTaxes = total;
    },
    //tokens
    [SET_UPLOAD_TOKEN](state, token) {
        state.uploadToken = token;
    },
    [SET_FILE_TOKEN](state, token) {
        state.fileToken = token;
    },
};

export default {
    namespaced: true,
    modules: {
        orgList: organisationListStore,
        orgForm: organisationFormStore,
        form: staffAccountStore,
        list: staffListStore,
        depList: departmentListStore,
        depForm: departmentFormStore,
        funcList: functionListStore,
        colAccList: collectorAccountListStore,
        documentSeries: documentSeriesStore,
        respList: responsibilityListStore,
        respForm: responsibilityFormStore,
        functionUsers: functionUsersStore,
        departmentUsers: departmentUsersStore,
        decisionList: decisionListStore,
        decisionForm: decisionFormStore,
        territorialUnitAllocation: territorialUnitAllocationListStore,
        territorialUnitAllocationForm: territorialUnitAllocationFormStore,
        urbanismRegulationZonesList: urbanismRegulationZonesListStore,
        urbanismRegulationZonesForm: urbanismRegulationZonesFormStore
    },
    state,
    actions,
    mutations,
    getters,
};
