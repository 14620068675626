import Errors from '@/shared/error/errors';
import ApiService from '@/shared/services/api.service';

// action types
export const GET_FUNCTIONS_BY_ORG = 'getFunctionsByOrg';
export const DELETE_FUNCTION = 'deleteFunction';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};

const actions = {
    [GET_FUNCTIONS_BY_ORG](context, {payload, id}) {
        context.commit('shared/activateLoading', 'admin/getFunctions', { root: true });
        payload.query = `organisation_id eq ${id} and ` + payload.query;
        const filters = { 
            params: { 
                ...payload, 
                pagination: true ,
                sort: '-system_default'
            } 
        };

        return new Promise((resolve, reject) => {
            ApiService.query('functions', filters)
                .then(({ data }) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data.pagination.total,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getFunctions', { root: true });
                })
        });
    },
    [DELETE_FUNCTION](context, functionId) {
        context.commit('shared/activateLoading', 'admin/deleteFunction', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.delete(`functions/${functionId}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteFunction', { root: true });
            })
        });
    },
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
