import Errors from '@/shared/error/errors';
import ApiService from '@/shared/services/api.service';

// action types
export const GET_DECISIONS = 'getDecisions';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};

const actions = {
    [GET_DECISIONS](context, {payload}) {
        context.commit('shared/activateLoading', 'admin/getDecisions', { root: true });
        const filters = { params: { ...payload, pagination: true } };

        return new Promise((resolve, reject) => {
            ApiService.query('decisions', filters)
                .then(({ data }) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data.pagination.total,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'admin/getDecisions', { root: true });
                })
        });
    },
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
