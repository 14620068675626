import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';

export const ADD_DECISION = 'addDecision';
export const UPDATE_DECISION = 'updateDecision';
export const DELETE_DECISION = 'deleteDecision';

const state = {};
const getters = {};
const actions = {
    [ADD_DECISION](context, payload) {
        context.commit('shared/activateLoading', 'admin/addDecision', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post(`/decisions?echo=true`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/addDecision', { root: true });
            })
        });
    },
    [UPDATE_DECISION](context, {id, payload}) {
        context.commit('shared/activateLoading', 'admin/updateDecision', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`decisions/${id}`, payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/updateDecision', { root: true });
            })
        });
    },
    [DELETE_DECISION](context, id) {
        context.commit('shared/activateLoading', 'admin/deleteDecision', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.delete(`decisions/${id}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteDecision', { root: true });
            })
        });
    },
}
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
